<template>
  <div
    class="flex flex-col mx-auto text-left"
    v-if="isAuthenticated === 'unauthorized'"
  >
    <form @submit.prevent="register(email, password)" class="reg-form">
      <h1 class="mb-4 text-3xl">Register</h1>
      <input
        type="text"
        placeholder="Email"
        v-model="email"
        class="block w-full px-4 py-2 mb-2 form-input"
      />
      <input
        type="password"
        placeholder="Password"
        v-model="password"
        class="block w-full px-4 py-2 mb-2 form-input"
      />
      <input
        type="submit"
        value="Register"
        class="px-4 py-2 mr-2 text-lg text-gray-100 bg-green-500 rounded hover:opacity-75"
      />
      <p class="mt-4">
        Have an account?
        <router-link to="/login" class="font-semibold text-white"
          >Login Here</router-link
        >
      </p>
    </form>
  </div>
</template>

<script>
// @ is an alias to /src
import { ref } from "vue";
import { isAuthenticated, register } from "../firebase.js";

export default {
  setup() {
    const email = ref("");
    const password = ref("");

    return {
      email,
      password,
      isAuthenticated,
      register,
    };
  },
};
</script>
